import { useCallback, useMemo } from 'react';
import { reverse } from 'lodash';
import { EsopGrant, Valuation } from '../../../../graphql';
import { formatDate, sortArrayByDate } from '../../../utils/date';
import useValuations from '../../valuations/hooks/useValuations';

const valuationLabel = (valuation: Valuation) => {
  if (!valuation) return '-';
  if (
    valuation.valuePerShare === null ||
    valuation.valuePerShare === undefined
  ) {
    return valuation.name;
  }

  return `${valuation.valuePerShare.betterFormat} - ${valuation.name}`;
};

const useGrantValuations = (
  grant?: EsopGrant,
  allowNon409aValuations = false,
) => {
  const [valuationsLoading, valuations] = useValuations();

  const filteredValuations = allowNon409aValuations
    ? valuations
    : valuations.filter((v) => v.fmvType === '409A');
  const reverseSortedValuations = reverse(
    sortArrayByDate(filteredValuations, 'valuationDate'),
  );
  const fairMarketValue = grant?.grantTerms?.fairMarketValue;

  const valuationOptions = useMemo(() => {
    const customOptions = [];
    if (!grant?.valuation && fairMarketValue) {
      customOptions.push({
        value: 'custom',
        label: fairMarketValue?.betterFormat,
        description: `Custom FMV`,
      });
    }
    const mappedValuations =
      reverseSortedValuations?.map((valuation) => ({
        value: valuation.id,
        label: valuationLabel(valuation),
        description: `Valuation date: ${formatDate(valuation?.valuationDate)}`,
      })) || [];
    return [...customOptions, ...mappedValuations];
  }, [reverseSortedValuations, grant]);

  const findClosestValuation = useCallback(
    (grantDate: string) =>
      reverseSortedValuations.find(
        (valuation: Valuation) =>
          new Date(valuation.valuationDate) <= new Date(grantDate),
      ),
    [valuations],
  );

  const defaultValuationValue = useMemo(() => {
    if (grant?.valuation?.id) return grant.valuation.id;
  }, [grant?.valuation?.id]);

  return {
    valuationOptions,
    findClosestValuation,
    valuationsLoading,
    valuations,
    defaultValuationValue,
  };
};

export default useGrantValuations;
